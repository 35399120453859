import calendar from './calendar.svg';
import clipboard from './clipboard.svg';
import clipboardLike from './clipboardLike.svg';
import clock from './clock.svg';
import computer from './computer.svg';
import heart from './heart.svg';
import heartCursor from './heartCursor.svg';
import mapPin from './mapPin.svg';
import mobile from './mobile.svg';
import smile from './smile.svg';
import chevron from './chevron.svg';
import doctorCalendar from './doctorCalendar.svg';
import graph from './graph.svg';
import medicalRecord from './medicalRecord.svg';
import telemedicine from './telemedicine.svg';
import arrowRight from './arrowRight.svg';
import tick from './tick.svg';
import cross from './cross.svg';

const icons = {
  calendar: { source: calendar },
  clipboard: { source: clipboard },
  clipboardLike: { source: clipboardLike },
  clock: { source: clock },
  computer: { source: computer },
  heart: { source: heart },
  heartCursor: { source: heartCursor },
  mapPin: { source: mapPin },
  mobile: { source: mobile },
  smile: { source: smile },
  chevron: { source: chevron },
  doctorCalendar: { source: doctorCalendar },
  graph: { source: graph },
  medicalRecord: { source: medicalRecord },
  telemedicine: { source: telemedicine },
  arrowRight: { source: arrowRight },
  tick: { source: tick },
  cross: { source: cross },
};

export default icons;
