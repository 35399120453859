import alemMedicina from './alem-medicina.svg';
import apm from './apm.svg';
import cdb from './cdb.svg';
import ePharma from './epharma.svg';
import labiExames from './labi-exames.svg';
import multiscan from './multiscan.svg';
import nabucoLopes from './nabuco-lopes.svg';
import plani from './plani.svg';
import sonimed from './sonimed.svg';
import uninove from './uninove.svg';

const logos = {
  alemMedicina: { source: alemMedicina, altText: 'Além da medicina' },
  apm: { source: apm, altText: 'APM - Associação Paulista de Medicina' },
  cdb: { source: cdb, altText: 'CDB Medicina Diagnóstica' },
  ePharma: { source: ePharma, altText: 'ePharma' },
  labiExames: { source: labiExames, altText: 'Labi Exames' },
  multiscan: { source: multiscan, altText: 'Multiscan Medicina Diagnóstica' },
  nabucoLopes: { source: nabucoLopes, altText: 'Laboratório Nabuco Lopes' },
  plani: { source: plani, altText: 'Plani Medicina Diagnóstica' },
  sonimed: { source: sonimed, altText: 'Sonimed Diagnósticos Avançados' },
  uninove: { source: uninove, altText: 'Uninove' },
};

export default logos;
