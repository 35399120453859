import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Text = styled.span`
  display: inline-block;
  isolation: isolate;
  position: relative;

  &:before {
    content: '';
    font-size: inherit;
    height: ${({ isMobile }) => (isMobile ? '1.1rem' : '1.6rem')};
    width: 100%;
    z-index: -1;
    bottom: 0;
    left: 0;
    display: block;
    position: absolute;

    background-color: ${({ orange, orangeLighter, purple }) => {
      switch (true) {
        case purple:
          return 'var(--purple-highlight)';

        case orangeLighter:
          return 'var(--orange-highlight-lighter)';

        case orange:
          return 'var(--orange-highlight)';

        default:
          return 'var(--gray-border)';
      }
    }};
  }
`;

const HighlightedText = ({
  orange = true,
  purple,
  orangeLighter,
  children,
  ...remainingProps
}) => {
  const { sm: isMobile } = useBreakpoint();

  return (
    <Text
      isMobile={isMobile}
      orange={orange}
      purple={purple}
      orangeLighter={orangeLighter}
      {...remainingProps}
    >
      {children}
    </Text>
  );
};

HighlightedText.propTypes = {
  orange: PropTypes.bool,
  purple: PropTypes.bool,
  orangeLighter: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default HighlightedText;
