import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import Link from 'src/components/common/link/Link';

const StyledHeader = styled.header`
  align-items: center;
  background: var(--white);
  border-bottom: 1px solid var(--gray-border);
  display: flex;
  justify-content: center;
  left: 0;
  padding: 1rem 3rem;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 2;
`;

const InnerWrapper = styled.div`
  align-self: stretch;
  max-width: var(--max-width);
  width: 100%;
`;

const Menu = styled.ul`
  align-items: center;
  display: flex;
  gap: 2.4rem;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const MenuItem = styled.li`
  min-width: 10rem;
`;

const hoverActiveLinkStyle = css`
  color: var(--purple);
  font-weight: 600;
  text-decoration: none;
  transition: all ease 0.4s;

  &:after {
    background: var(--orange);
  }
`;

const linkStyle = css`
  color: var(--gray-lighter);
  font-size: 1.4rem;
  line-height: 2.4rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  transition: all ease 0.4s;

  &:after {
    background: transparent;
    bottom: -1.3rem;
    content: '';
    display: block;
    height: 0.3rem;
    left: -1rem;
    position: absolute;
    width: calc(100% + 2rem);
    transition: all ease 0.4s;
  }

  &:hover {
    ${hoverActiveLinkStyle}
  }
`;

const getStyle = (isCurrent) => css`
  ${linkStyle};
  ${isCurrent && hoverActiveLinkStyle};
`;

const DesktopHeader = ({ pageType }) => {
  const { t } = useTranslation();
  const links = t('header.links', { returnObjects: true });

  return (
    <StyledHeader>
      <InnerWrapper>
        <Menu role="menu" tabIndex="0">
          {links.map(({ type, term, route }, index) => (
            <MenuItem role="none" key={`link-${index}`}>
              <Link
                role="menuitem"
                isRoute
                to={route}
                style={() => getStyle(type === pageType)}
              >
                {t(term)}
              </Link>
            </MenuItem>
          ))}
        </Menu>
      </InnerWrapper>
    </StyledHeader>
  );
};

DesktopHeader.propTypes = {
  pageType: PropTypes.oneOf(['patient', 'doctor', 'enterprise']),
};

export default DesktopHeader;
