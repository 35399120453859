import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Wrapper = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100vw;
`;

const InnerWrapper = styled.div`
  align-self: stretch;
  max-width: ${({ isMobile }) =>
    isMobile ? 'width: 100%' : 'var(--max-width)'};
  width: 100%;
`;

const Section = ({ children, ...remainingProps }) => {
  const breakpoints = useBreakpoint();

  return (
    <Wrapper {...remainingProps}>
      <InnerWrapper isMobile={breakpoints.sm}>{children}</InnerWrapper>
    </Wrapper>
  );
};

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Section;
