import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Section = styled.section`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  margin-top: ${({ isMobile }) => (isMobile ? '0' : '4.5rem')};
  position: relative;
  width: 100vw;
`;

const InnerWrapper = styled.div`
  align-self: stretch;
  max-width: var(--max-width);
  width: 100%;
`;

const Hero = ({ children, ...remainingProps }) => {
  const { sm: isMobile } = useBreakpoint();

  return (
    <Section isMobile={isMobile} {...remainingProps}>
      <InnerWrapper>{children}</InnerWrapper>
    </Section>
  );
};

Hero.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Hero;
