import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const HeaderTags = ({ title, metaItems }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {metaItems.map((item, index) => (
        <meta key={index} {...item} />
      ))}
    </Helmet>
  );
};

HeaderTags.propTypes = {
  title: PropTypes.string.isRequired,
  metaItems: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

export default HeaderTags;
