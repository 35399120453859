import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { Link as GatsbyLink } from 'gatsby';

import doctor from '../../../images/common/doctor.png'
import pacient from '../../../images/common/pacient.png'

const buttonAsLinkStyle = {
  primary: css`
    background-color: var(--orange);
    border-radius: 0.8rem;
    border: 1px solid transparent;
    color: var(--purple);
    font-size: 1.4rem;
    font-weight: 600;
    isolation: isolate;
    padding: 1rem 2rem;
    text-decoration: none;
    transition: all 0.4s ease;

    &:hover {
      background: var(--orange-hover);
      border-color: var(--orange);
      color: var(--white);
      transition: all 0.4s ease;
    }
  `,

  secondary: css`
    background-color: var(--white);
    border-radius: 0.8rem;
    border: 1px solid var(--orange);
    color: var(--purple);
    font-size: 1.4rem;
    font-weight: 600;
    isolation: isolate;
    padding: 1rem 2rem;
    text-decoration: none;
    transition: all 0.4s ease;

    &:hover {
      background: var(--purple-lighter);
      border-color: var(--orange);
      transition: all 0.4s ease;
    }
  `,

  tertiary: css`
    background-color: var(--white-variant);
    border-radius: 0.8rem;
    border: 1px solid transparent;
    color: var(--purple-button);
    font-size: 1.4rem;
    font-weight: 600;
    isolation: isolate;
    padding: 1rem 2rem;
    text-decoration: none;
    transition: all 0.4s ease;

    &:hover {
      background: var(--purple);
      border-color: var(--purple);
      color: var(--white);
      transition: all 0.4s ease;
    }
  `,
};

const StyledDropdownLink = styled.div`
  position: relative;

  &:hover {
    ul {
      transform: rotateX(0)
    }
  }

  ul {
    // display: none;
    background: #F3F5F6;
    box-shadow: 0px 5.33333px 16px rgba(146, 140, 151, 0.25);
    border-radius: 10.6667px;
    position: absolute;
    top: 28px;
    list-style: none;
    padding: 15px;
    margin: 0;
    z-index: 1;
    width: 100%;
    transform: rotateX(-90deg);
    transform-origin: top center;
    transition: all 15ms ease-in;
    min-width: max-content;

    li {
      padding-bottom: 15px;

      &:last-child {
        padding-bottom: 0;
      }

      a {
        padding-left: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #3C3C43;
        text-decoration: none;

        &:hover {
          color: var(--orange-hover);
          transition-timing-function: ease;
        }
      }
    }
  }
`;

const StyledGatsbyLink = styled(GatsbyLink)`
  ${({ primary, secondary, tertiary }) => {
    switch (true) {
      case tertiary:
        return buttonAsLinkStyle.tertiary;

      case secondary:
        return buttonAsLinkStyle.secondary;

      case primary:
      default:
        return buttonAsLinkStyle.primary;
    }
  }}
`;

const StyledLink = styled.a`
  ${({ primary, secondary, tertiary }) => {
    switch (true) {
      case tertiary:
        return buttonAsLinkStyle.tertiary;

      case secondary:
        return buttonAsLinkStyle.secondary;

      case primary:
      default:
        return buttonAsLinkStyle.primary;
    }
  }}
`;

const linkAsDropdown = ({
  isRoute = false,
  to,
  primary = true,
  secondary = false,
  tertiary = false,
  children,
  sub_links,
  ...remainingProps
}) => {
  return isRoute ? (
    <StyledGatsbyLink
      tabIndex="0"
      primary={primary}
      secondary={secondary}
      tertiary={tertiary}
      to={to}
      {...remainingProps}
    >
      {children}
    </StyledGatsbyLink>
  ) : (
    <StyledDropdownLink>
      <StyledLink
        tabIndex="0"
        primary={primary}
        secondary={secondary}
        tertiary={tertiary}
        href={to}
        {...remainingProps}
      >
        {children}
      </StyledLink>
      <ul>
      {sub_links.map(({link, name, icon}) => (<li key={name}><img src={icon === "doctor" ? doctor : pacient } alt={`icon-${name}`} /><a href={link}>{name}</a></li>))}
      </ul>
    </StyledDropdownLink>
  );
};

linkAsDropdown.propTypes = {
  isRoute: PropTypes.bool,
  to: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  sub_links: PropTypes.array.isRequired,
};

export default linkAsDropdown;
