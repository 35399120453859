import React from 'react';
import styled from '@emotion/styled';

import PropTypes from 'prop-types';

const Wrapper = styled.main`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
`;

const PageWrapper = ({ children }) => {
  return <Wrapper tabIndex="0">{children}</Wrapper>;
};

PageWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default PageWrapper;
