import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'gatsby-plugin-react-i18next';


import icons from './icons';

const SocialMedia = ({ profile, ...remaningProps }) => {
  const { t } = useTranslation();
  const { source, link, title } = icons[profile];

  return (
    <a href={t(link)} tabIndex={0} target="_blank" {...remaningProps} >
      <img alt={t(title)} src={source} width={20} height={20} />
    </a>
  );
};

SocialMedia.propTypes = {
  profile: PropTypes.oneOf(Object.keys(icons)).isRequired,
};

export default SocialMedia;
