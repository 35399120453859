import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const StyledP = styled.p`
  color: var(--gray-darker);
  font-size: ${({ isMobile }) => (isMobile ? '1.6rem' : '1.8rem')};
  font-weight: 400;
  line-height: 2.65rem;
  margin: 0;
  padding: 0;
  text-align: ${({ align }) => align ?? 'left'};
`;

const Text = ({ align = 'left', children, ...remainingProps }) => {
  const { sm: isMobile } = useBreakpoint();

  return (
    <StyledP isMobile={isMobile} align={align} {...remainingProps}>
      {children}
    </StyledP>
  );
};

Text.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Text;
