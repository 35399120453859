import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { Link as GatsbyLink } from 'gatsby';

const linkStyle = css`
  color: var(--gray-lighter);
  font-size: 1.2rem;
  text-decoration: none;
  transition: all ease 0.4s;

  &:hover {
    color: var(--purple);
    text-decoration: underline;
    text-decoration-skip-ink: auto;
    transition: all ease 0.4s;
  }

  &:focus {
    outline: 0.1rem dashed var(--gray-lighter);
    outline-offset: 0.5rem;
    transition: all ease 0.4s;
  }
`;

const StyledGatsbyLink = styled(GatsbyLink)`
  ${linkStyle}
`;

const StyledLink = styled.a`
  ${linkStyle}
`;

const Link = ({ isRoute = false, to, style, children, ...remainingProps }) => {
  return isRoute ? (
    <StyledGatsbyLink tabIndex="0" css={style} to={to} {...remainingProps}>
      {children}
    </StyledGatsbyLink>
  ) : (
    <StyledLink tabIndex="0" css={style} href={to} {...remainingProps}>
      {children}
    </StyledLink>
  );
};

Link.propTypes = {
  isRoute: PropTypes.bool,
  to: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Link;
