import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import Link from 'src/components/common/link/Link';

import appstore from './image/appstore.png';
import googleplay from './image/googleplay.png';

const Image = styled.img`
  border: 0;
  object-fit: cover;
`;

const badgesMap = {
  apple: { image: appstore },
  google: { image: googleplay },
};

const AppStoreBadge = ({ type }) => {
  const { t } = useTranslation();
  const appStoreLinks = t('app_store', { returnObjects: true });

  for (const store of Object.keys(appStoreLinks)) {
    badgesMap[store].link = appStoreLinks[store];
  }

  const { link, image } = badgesMap[type];

  return (
    <Link to={link}>
      <Image src={image} />
    </Link>
  );
};

AppStoreBadge.propTypes = {
  type: PropTypes.oneOf(Object.keys(badgesMap)).isRequired,
};

export default AppStoreBadge;
