import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import useRenderTitle from 'src/hooks/useRenderTitle';

import Section from 'src/components/common/section/Section';
import Title from 'src/components/common/title/Title';
import HighlightedText from 'src/components/common/highlighted-text/HighlightedText';
import FlexBox from 'src/components/common/flex-box/FlexBox';
import Accordion from 'src/components/common/accordion/Accordion';

const StyledSection = styled(Section)`
  padding: ${({ isMobile }) => (isMobile ? '5rem 2rem' : '13.8rem 2rem')};
`;

const StyledFlexBox = styled(FlexBox)`
  margin: 5rem auto 0 auto;
`;

const Faqs = ({ title, faqs }) => {
  const { sm: isMobile } = useBreakpoint();

  const { render: renderMobileTitle } = useRenderTitle({
    titleComponent: Title,
    titleComponentProps: {
      css: css`
        text-align: left;
      `,
    },
    highlightComponent: HighlightedText,
    text: title,
  });

  const renderDesktopTitle = () => {
    const firstSentence = title.slice(0, 2);
    const secondSentence = title.slice(-1);

    const { render: renderFirstSentence } = useRenderTitle({
      titleComponent: Title,
      titleComponentProps: {
        css: css`
          text-align: center;
        `,
      },
      highlightComponent: HighlightedText,
      text: firstSentence,
    });

    const { render: renderSecondSentence } = useRenderTitle({
      titleComponent: Title,
      titleComponentProps: {
        css: css`
          text-align: center;
        `,
      },
      highlightComponent: HighlightedText,
      text: secondSentence,
    });

    return (
      <>
        {renderFirstSentence()}
        <br />
        {renderSecondSentence()}
      </>
    );
  };

  return (
    <StyledSection id="faq" isMobile={isMobile}>
      {isMobile ? renderMobileTitle() : renderDesktopTitle()}
      <StyledFlexBox
        alignItems="flex-start"
        justifyContent="center"
        flexWrap="wrap"
        width="100%"
      >
        {faqs.map(({ question, answer }, index) => (
          <Accordion
            key={`faq-${index}`}
            title={question}
            text={{ __html: answer }}
            hasBottomBorder={index === faqs.length - 1}
          />
        ))}
      </StyledFlexBox>
    </StyledSection>
  );
};

Faqs.propTypes = {
  title: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({ text: PropTypes.string }),
      PropTypes.shape({ highlight: PropTypes.string }),
    ]),
  ),
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    }),
  ),
};

export default Faqs;
