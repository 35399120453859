import logo from './logo.svg';
import patient from './patient.svg';
import doctor from './doctor.svg';
import enterprise from './enterprise.svg';
import icon from './icon.svg';

const logos = {
  logo: { source: logo, altText: 'logo.default' },
  patient: { source: patient, altText: 'logo.patient' },
  doctor: { source: doctor, altText: 'logo.doctor' },
  enterprise: { source: enterprise, altText: 'logo.enterpise' },
  icon: { source: icon, altText: 'logo.default' },
};

export default logos;
