import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import iconsMap from './icons';

const Image = styled.img`
  border: 0;
  object-fit: contain;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

const Icon = ({ icon, height = 15, width = 15, ...remainingProps }) => {
  return (
    <Image
      src={iconsMap[icon].source}
      height={height}
      width={width}
      {...remainingProps}
    />
  );
};

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(iconsMap)).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Icon;
