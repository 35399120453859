import facebook from './facebook.svg';
import instagram from './instagram.svg';
import linkedin from './linkedin.svg';

const icons = {
  facebook: {
    source: facebook,
    link: 'social_media.facebook.link',
    title: 'social_media.facebook.title',
  },
  instagram: {
    source: instagram,
    link: 'social_media.instagram.link',
    title: 'social_media.instagram.title',
  },
  linkedin: {
    source: linkedin,
    link: 'social_media.linkedin.link',
    title: 'social_media.linkedin.title',
  },
};

export default icons;
