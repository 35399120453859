import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const StyledH2 = styled.h2`
  margin: 0;
  padding: 0;

  &,
  & > * {
    color: ${({ purple, white }) => {
      switch (true) {
        case white:
          return 'var(--white)';

        case purple:
          return 'var(--purple)';

        default:
          return 'var(--gray-darker)';
      }
    }};
    font-size: ${({ isMobile }) => (isMobile ? '2.8rem' : '4.8rem')};
  }
`;

const Title = ({ children, purple = true, white, ...remainingProps }) => {
  const { sm: isMobile } = useBreakpoint();

  return (
    <StyledH2
      isMobile={isMobile}
      purple={purple}
      white={white}
      {...remainingProps}
    >
      {children}
    </StyledH2>
  );
};

Title.propTypes = {
  purple: PropTypes.bool,
  white: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Title;
