import React, { useState } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import disableScroll from 'disable-scroll';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import FlexBox from 'src/components/common/flex-box/FlexBox';
import Link from 'src/components/common/link/Link';
import LinkAsDropdownMobile from 'src/components/common/link-as-dropdown-mobile/LinkAsDropdownMobile';

const hoverActiveLinkStyle = css`
  color: var(--purple);
  font-weight: 600;
  text-decoration: none;
  transition: all ease 0.4s;

  &:after {
    background: var(--orange);
  }
`;

const linkStyle = css`
  color: var(--gray-lighter);
  font-size: 1.4rem;
  line-height: 2.4rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  transition: all ease 0.4s;

  &:after {
    background: transparent;
    bottom: -1.3rem;
    content: '';
    display: block;
    height: 0.3rem;
    left: -1rem;
    position: absolute;
    width: calc(100% + 2rem);
    transition: all ease 0.4s;
  }

  &:hover {
    ${hoverActiveLinkStyle}
  }
`;

const InnerWrapper = styled.div`
  align-self: stretch;
  max-width: var(--max-width);
  width: 100%;
`;

const Menu = styled.ul`
  align-items: center;
  display: flex;
  gap: 2.4rem;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const MenuItem = styled.li`
  min-width: 10rem;
`;

const ButtonWrapper = styled(FlexBox)`
  height: 4.8rem;
  isolation: isolate;
  position: absolute;
  right: 0;
  top: 0;
  width: 5.3rem;
  z-index: 10;
`;

const Button = styled.button`
  align-items: center;
  align-self: stretch;
  background: var(--white);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0;
  padding: 1.6rem;
  width: 100%;
`;

const Hamburger = styled.span`
  background-color: var(--purple);
  border: 0.1rem solid var(--purple);
  border-radius: 0.5rem;
  display: block;
  height: 0.1rem;
  width: 100%;
`;

const MotionHamburger = motion(Hamburger);

const MenuWrapper = styled(FlexBox)`
  background-color: var(--white);
  inset: 0 0 0 0;
  isolation: isolate;
  padding: 4rem;
  position: fixed;
  z-index: 8;
`;

const List = styled.ul`
  align-content: flex-start;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  padding: 12px 0;
  margin: 0;
  width: 100%;
`;

const ButtonLink = styled.button`
  padding: 1rem;
  margin: 1em 0 0 1em;
  color: var(--white);
  background: var(--purple);
  border: none;
  border-radius: 20px;
`;

const StyledLink = styled(Link)`
  color: var(--purple);
  font-size: 2rem;
`;

const StyledLinkAsDropdownMobile = styled(LinkAsDropdownMobile)`
  background: none;
  padding: 0;
  color: var(--purple);
  font-size: 2rem;
  font-weight: normal;
  
  ul {
    position: relative;
    background: none;
  }

  .section-menu-title {
    font-size: 1rem;
    font-weight: bold;
  }
`;

const getStyle = (isCurrent) => css`
  ${linkStyle};
  ${isCurrent && hoverActiveLinkStyle};
`;

const MobileHeader = ({ pageType }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const links = t(`mobile_menu_links.${pageType}`, { returnObjects: true });

  const linksTop = t('header.links', { returnObjects: true });
  
  const handleClick = () => {
    setIsOpen(!isOpen);
    disableScroll[isOpen ? 'off' : 'on']();
  };

  return (
    <>
      <ButtonWrapper>
        <Button
          aria-label={isOpen ? t('header.close_menu') : t('header.open_menu')}
          onClick={handleClick}
        >
          <MotionHamburger
            aria-hidden="true"
            animate={
              isOpen
                ? { transformOrigin: 'top right', rotate: '-45deg' }
                : { transformOrigin: 'top right', rotate: '0deg' }
            }
          />
          <MotionHamburger
            aria-hidden="true"
            animate={isOpen ? { opacity: 0 } : { opacity: 1 }}
          />
          <MotionHamburger
            aria-hidden="true"
            animate={
              isOpen
                ? { transformOrigin: 'bottom right', rotate: '45deg' }
                : { transformOrigin: 'bottom right', rotate: '0deg' }
            }
          />
        </Button>
      </ButtonWrapper>
      <MenuWrapper
        as={motion.div}
        initial={false}
        transition={{ ease: 'easeOut', duration: 0.3 }}
        animate={
          isOpen
            ? { transform: 'translateX(0%)' }
            : { transform: 'translateX(100%)' }
        }
        alignItems="flex-start"
        justifyContent="center"
        height="100vmax"
        width="100vw"
      >
        <List role="menu">
          {links.map(({ link, name, type, sub_links }, index) => (
            <Item key={`mobile-menu-link-${index}`} role="none">
                {type === 'link' ? (
                  <StyledLink role="menuitem" to={link} onClick={handleClick}>{name}</StyledLink>
                ) : (
                  <StyledLinkAsDropdownMobile role="menuitem" to={link} sub_links={sub_links} onClick={handleClick}>{name}</StyledLinkAsDropdownMobile>
                )}
            </Item>
          ))}
        </List>
      </MenuWrapper>

      <InnerWrapper>
        <Menu aria-role="menu" tabIndex="0">
          {linksTop.map(({ type, term, route }, index) => (
            <MenuItem aria-role="none" key={`link-${index}`}>
              <ButtonLink
                aria-role="menuitem"
                isRoute
                onClick={() => {window.location.href = route}}
              >
                {t(term)}
              </ButtonLink>
            </MenuItem>
          ))}
        </Menu>
      </InnerWrapper>
    </>
  );
};

MobileHeader.propTypes = {
  pageType: PropTypes.oneOf(['patient', 'doctor', 'enterprise']),
};

export default MobileHeader;
