import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Box = styled.div`
  align-content: ${({ alignContent }) => alignContent || 'normal'};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  align-self: ${({ alignSelf }) => alignSelf || 'auto'};
  column-gap: ${({ columnGap }) => columnGap || 'unset'};
  display: flex;
  height: ${({ height }) => height || 'auto'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
  justify-content: ${({ justifyContent }) => justifyContent || 'normal'};
  gap: ${({ gap }) => gap || 'auto'};
  order: ${({ order }) => order || '0'};
  row-gap: ${({ rowGap }) => rowGap || 'auto'};
  width: ${({ width }) => width || 'auto'};
`;

const FlexBox = ({
  flexDirection,
  flexWrap,
  justifyContent,
  alignItems,
  alignContent,
  alignSelf,
  gap,
  rowGap,
  columnGap,
  order,
  width,
  height,
  children,
  ...remainingProps
}) => {
  return (
    <Box
      flexDirection={flexDirection}
      flexWrap={flexWrap}
      justifyContent={justifyContent}
      alignItems={alignItems}
      alignContent={alignContent}
      alignSelf={alignSelf}
      gap={gap}
      rowGap={rowGap}
      columnGap={columnGap}
      order={order}
      width={width}
      height={height}
      {...remainingProps}
    >
      {children}
    </Box>
  );
};

FlexBox.propTypes = {
  flexDirection: PropTypes.oneOf([
    'row',
    'row-reverse',
    'column',
    'column-reverse',
  ]),
  flexWrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
  justifyContent: PropTypes.oneOf([
    'normal',
    'flex-start',
    'flex-end',
    'start',
    'end',
    'left',
    'right',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  alignItems: PropTypes.oneOf([
    'stretch',
    'flex-start',
    'start',
    'self-start',
    'flex-end',
    'end',
    'self-end',
    'center',
    'baseline',
  ]),
  alignContent: PropTypes.oneOf([
    'normal',
    'flex-start',
    'start',
    'flex-end',
    'end',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
    'stretch',
  ]),
  alignSelf: PropTypes.oneOf([
    'auto',
    'flex-start',
    'flex-end',
    'center',
    'baseline',
    'stretch',
  ]),
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default FlexBox;
