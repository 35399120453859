import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import logos from './logos';

const StyledImg = styled.img`
  border: 0;
  object-fit: contain;
`;

const Logo = ({ type = 'logo', width = 182, height = 45 }) => {
  const { t } = useTranslation();
  const { source, altText } = logos[type];

  return (
    <StyledImg src={source} alt={t(altText)} height={height} width={width} />
  );
};

Logo.propTypes = {
  type: PropTypes.oneOf(Object.keys(logos)),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Logo;
