import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import logos from './logos';

const Image = styled.img`
  border: 0;
  object-fit: contain;
`;

const grayscaleStyle = css`
  filter: grayscale(1);
`;

const PartnerLogo = ({
  partner,
  width = 30,
  height = 30,
  grayscale = false,
  ...remainingProps
}) => {
  return (
    <Image
      src={logos[partner].source}
      alt={logos[partner].altText}
      width={width}
      height={height}
      css={grayscale && grayscaleStyle}
      {...remainingProps}
    />
  );
};

PartnerLogo.propTypes = {
  partner: PropTypes.oneOf(Object.keys(logos)).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  grayscale: PropTypes.bool,
};

export default PartnerLogo;
