import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const StyledDetails = styled.details`
  border-top: 1px solid var(--purple-lighter);
  width: 100%;

  &[open] {
    summary:after {
      content: '-';
      right: 3rem;
    }
  }

  summary:after {
    align-items: center;
    content: '+';
    color: var(--orange);
    display: flex;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 4rem;
    font-weight: 100;
    height: 100%;
    line-height: 1;
    position: absolute;
    right: 2.7rem;
    top: -0.1rem;
  }

  ${({ hasBottomBorder }) =>
    hasBottomBorder &&
    css`
      border-bottom: 1px solid var(--purple-lighter);
    `}
`;

const StyledSummary = styled.summary`
  background: none;
  border: 0;
  color: var(--purple);
  cursor: pointer;
  flex: 1;
  font-size: ${({ isMobile }) => (isMobile ? '1.8rem' : '2.4rem')};
  list-style: none;
  padding: ${({ isMobile }) =>
    isMobile ? '1.6rem 4.5rem 1.6rem 1.8rem' : '2.6rem 3rem'};
  position: relative;
  text-align: left;
  transition: all 0.4s ease;
  width: 100%;

  &::-webkit-details-marker {
    display: none;
  }
`;

const Panel = styled.div`
  color: var(--gray);
  font-size: 1.4rem;
  line-height: 1.3;
  padding: ${({ isMobile }) => (isMobile ? '1.6rem' : '2.6rem')};
  transition: max-height 0.4s ease;
`;

const Accordion = ({
  title,
  text,
  opened = false,
  hasBottomBorder = false,
}) => {
  const { sm: isMobile } = useBreakpoint();

  return (
    <StyledDetails open={opened} hasBottomBorder={hasBottomBorder}>
      <StyledSummary isMobile={isMobile}>{title}</StyledSummary>
      <Panel isMobile={isMobile} dangerouslySetInnerHTML={text} />
    </StyledDetails>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.shape({ __html: PropTypes.string }),
  opened: PropTypes.bool,
  hasBottomBorder: PropTypes.bool,
};

export default Accordion;
