import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const StyledH3 = styled.h3`
  font-size: ${({ isMobile }) => (isMobile ? '1.8rem' : '2.4rem')};
  font-weight: 700;
  line-height: 3.2rem;
  margin: 0;
  padding: 0;

  color: ${({ purple, orange, turquoise, gray, white }) => {
    switch (true) {
      case purple:
        return 'var(--purple)';

      case orange:
        return 'var(--orange)';

      case turquoise:
        return 'var(--turquoise)';

      case gray:
        return 'var(--gray)';

      case white:
        return 'var(--white)';

      default:
        return 'var(--black)';
    }
  }};
`;

const Subtitle = ({
  purple = false,
  orange = false,
  turquoise = false,
  gray = false,
  white = false,
  children,
  ...remainingProps
}) => {
  const { sm: isMobile } = useBreakpoint();

  return (
    <StyledH3
      purple={purple}
      orange={orange}
      turquoise={turquoise}
      gray={gray}
      white={white}
      isMobile={isMobile}
      {...remainingProps}
    >
      {children}
    </StyledH3>
  );
};

Subtitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  purple: PropTypes.bool,
  orange: PropTypes.bool,
  turquoise: PropTypes.bool,
  gray: PropTypes.bool,
  white: PropTypes.bool,
};

export default Subtitle;
