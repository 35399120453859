import React, { useState, useEffect }  from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

const buttonAsLinkStyle = {
  primary: css`
    background-color: var(--orange);
    border-radius: 0.8rem;
    border: 1px solid transparent;
    color: var(--purple);
    font-size: 1.4rem;
    font-weight: 600;
    isolation: isolate;
    padding: 1rem 2rem;
    text-decoration: none;
    transition: all 0.4s ease;

    &:hover {
      background: var(--orange-hover);
      border-color: var(--orange);
      color: var(--white);
      transition: all 0.4s ease;
    }
  `,

  secondary: css`
    background-color: var(--white);
    border-radius: 0.8rem;
    border: 1px solid var(--orange);
    color: var(--purple);
    font-size: 1.4rem;
    font-weight: 600;
    isolation: isolate;
    padding: 1rem 2rem;
    text-decoration: none;
    transition: all 0.4s ease;

    &:hover {
      background: var(--purple-lighter);
      border-color: var(--orange);
      transition: all 0.4s ease;
    }
  `,

  tertiary: css`
    background-color: var(--white-variant);
    border-radius: 0.8rem;
    border: 1px solid transparent;
    color: var(--purple-button);
    font-size: 1.4rem;
    font-weight: 600;
    isolation: isolate;
    padding: 1rem 2rem;
    text-decoration: none;
    transition: all 0.4s ease;

    &:hover {
      background: var(--purple);
      border-color: var(--purple);
      color: var(--white);
      transition: all 0.4s ease;
    }
  `,
};

const StyledDropdownLink = styled.div`
  position: relative;

  &:hover {
    ul {
      transform: rotateX(0)
    }
  }

  button {
    width: 100%;
    display: flex;
    border: none;
    color: var(--purple-button);
    font-size: 2rem;
    isolation: isolate;
    text-decoration: none;
    background: none;
    padding: 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
    list-style: none;
    margin: 0;
    margin-top: 5px;
    padding: 0;
    z-index: 1;
    width: 100%;
    transform: rotateX(-90deg);
    transform-origin: top center;
    transition: all 15ms ease-in;

    li {
      background: #F3F5F6;
      padding: 12px;
      padding-right: 0;

      a {
        font-size: 14px;
        font-weight: 400;
        color: #3C3C43;
        text-decoration: none;

        &:hover {
          color: var(--orange-hover);
          transition-timing-function: ease;
        }
      }
    }
  }
`;

const linkAsDropdownMobile = ({
  children,
  sub_links
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToogleDropdown = () => setIsOpen(!isOpen)

  return (
    <StyledDropdownLink>
      <button type='button' onClick={handleToogleDropdown}>
        {children}
      </button>
      {isOpen === true && (<ul>
        {sub_links.map(({link, name}) => (<li><a href={link}>{name}</a></li>))}
      </ul>)}
    </StyledDropdownLink>
  );
}

linkAsDropdownMobile.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  sub_links: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default linkAsDropdownMobile;
