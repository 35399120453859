import React from 'react';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import MobileHeader from './components/mobile-header/MobileHeader';
import DesktopHeader from './components/desktop-header/DesktopHeader';

const Header = ({ pageType }) => {
  const breakpoints = useBreakpoint();

  return breakpoints.sm ? (
    <MobileHeader pageType={pageType} />
  ) : (
    <DesktopHeader pageType={pageType} />
  );
};

Header.propTypes = {
  pageType: PropTypes.oneOf(['patient', 'doctor', 'enterprise']),
};

export default Header;
