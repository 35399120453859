import React from 'react';
import PropTypes from 'prop-types';

/**
 * Takes in the `TitleComponent`, the `HighlightComponent`,
 * and the text array. Parses the text and mounts a title
 * with the highlighted fragments.
 *
 * @param {React.FC} titleComponent The title component
 * @param {React.FC} highlightComponent The highlight component
 * @param {Array<{ text: String | highlight: String }>} text The text in array format
 * @returns The mounted text wrapped by the `TitleComponent`.
 */
const useRenderTitle = (
  {
    titleComponent: TitleComponent,
    titleComponentProps,
    highlightComponent: HighlightComponent,
    highlightComponentProps,
    text = [],
  } = {
    titleComponent,
    titleComponentProps,
    highlightComponent,
    highlightComponentProps,
    text,
  },
) => {
  const render = () => {
    const parsedText = text.map((item) => {
      const [[key, value]] = Object.entries(item);
      return {
        type: key === 'text' ? 'text' : 'highlight',
        value,
      };
    });

    const ariaLabel = text
      .map((item) => Object.values(item).map((value) => value))
      .join(' ');

    return (
      <TitleComponent aria-label={ariaLabel} {...titleComponentProps}>
        {parsedText.map(({ type, value }, index) =>
          type === 'highlight' ? (
            <React.Fragment key={`highlighted-${index}`}>
              {index > 0 && <>&nbsp;</>}
              <HighlightComponent {...highlightComponentProps}>
                {value}
              </HighlightComponent>
              {index < parsedText.length && <>&nbsp;</>}
            </React.Fragment>
          ) : (
            value
          ),
        )}
      </TitleComponent>
    );
  };

  return { render };
};

useRenderTitle.propTypes = {
  titleComponent: PropTypes.node.isRequired,
  titleComponentProps: PropTypes.object,
  highlightComponent: PropTypes.node.isRequired,
  highlightComponentProps: PropTypes.object,
  text: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({ text: PropTypes.string }),
      PropTypes.shape({ highlight: PropTypes.string }),
    ]),
  ),
};

export default useRenderTitle;
