import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  border-radius: 1.6rem;
  display: block;
  height: ${({ height }) => `${height}px`};
  position: relative;
  width: ${({ width }) => `${width}px`};

  &:after {
    background-color: ${({ purple, orange }) => {
      switch (true) {
        case purple:
          return 'var(--purple-shadow)';

        case orange:
          return 'var(--orange-shadow)';

        default:
          return 'var(--gray-lighter)';
      }
    }};
    border-radius: 1.6rem;
    content: '';
    display: block;
    height: ${({ height }) => `${height}`};
    left: 3.93%;
    position: absolute;
    top: 5.78%;
    width: ${({ width }) => `${width}`};
    z-index: -1;
  }
`;

const Image = styled.img`
  border: 0;
  border-radius: 1.6rem;
  object-fit: cover;
  position: relative;
`;

const HighlightedImage = ({
  source,
  height = 570,
  width = 508,
  purple = false,
  orange = false,
  isResponsive = false,
  ...remainingProps
}) => {
  const computedWidth = isResponsive ? '100%' : `${width}px`;
  const computedHeight = isResponsive ? '100%' : `${height}px`;

  return (
    <Wrapper
      width={computedWidth}
      height={computedHeight}
      purple={purple}
      orange={orange}
    >
      <Image
        src={source}
        height={computedHeight}
        width={computedWidth}
        purple={purple}
        orange={orange}
        {...remainingProps}
      />
    </Wrapper>
  );
};

HighlightedImage.propTypes = {
  source: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  purple: PropTypes.bool,
  orange: PropTypes.bool,
  isResponsive: PropTypes.bool,
};

export default HighlightedImage;
