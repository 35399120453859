import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { Link as GatsbyLink } from 'gatsby';

const buttonAsLinkStyle = {
  primary: css`
    background-color: var(--orange);
    border-radius: 0.8rem;
    border: 1px solid transparent;
    color: var(--purple);
    font-size: 1.4rem;
    font-weight: 600;
    isolation: isolate;
    padding: 1rem 2rem;
    text-decoration: none;
    transition: all 0.4s ease;

    &:hover {
      background: var(--orange-hover);
      border-color: var(--orange);
      color: var(--white);
      transition: all 0.4s ease;
    }
  `,

  secondary: css`
    background-color: var(--white);
    border-radius: 0.8rem;
    border: 1px solid var(--orange);
    color: var(--purple);
    font-size: 1.4rem;
    font-weight: 600;
    isolation: isolate;
    padding: 1rem 2rem;
    text-decoration: none;
    transition: all 0.4s ease;

    &:hover {
      background: var(--purple-lighter);
      border-color: var(--orange);
      transition: all 0.4s ease;
    }
  `,

  tertiary: css`
    background-color: var(--white-variant);
    border-radius: 0.8rem;
    border: 1px solid transparent;
    color: var(--purple-button);
    font-size: 1.4rem;
    font-weight: 600;
    isolation: isolate;
    padding: 1rem 2rem;
    text-decoration: none;
    transition: all 0.4s ease;

    &:hover {
      background: var(--purple);
      border-color: var(--purple);
      color: var(--white);
      transition: all 0.4s ease;
    }
  `,
};

const StyledGatsbyLink = styled(GatsbyLink)`
  ${({ primary, secondary, tertiary }) => {
    switch (true) {
      case tertiary:
        return buttonAsLinkStyle.tertiary;

      case secondary:
        return buttonAsLinkStyle.secondary;

      case primary:
      default:
        return buttonAsLinkStyle.primary;
    }
  }}
`;

const StyledLink = styled.a`
  ${({ primary, secondary, tertiary }) => {
    switch (true) {
      case tertiary:
        return buttonAsLinkStyle.tertiary;

      case secondary:
        return buttonAsLinkStyle.secondary;

      case primary:
      default:
        return buttonAsLinkStyle.primary;
    }
  }}
`;

const LinkAsButton = ({
  isRoute = false,
  to,
  primary = true,
  secondary = false,
  tertiary = false,
  children,
  ...remainingProps
}) => {
  return isRoute ? (
    <StyledGatsbyLink
      tabIndex="0"
      primary={primary}
      secondary={secondary}
      to={to}
      {...remainingProps}
    >
      {children}
    </StyledGatsbyLink>
  ) : (
    <StyledLink
      tabIndex="0"
      primary={primary}
      secondary={secondary}
      tertiary={tertiary}
      href={to}
      {...remainingProps}
    >
      {children}
    </StyledLink>
  );
};

LinkAsButton.propTypes = {
  isRoute: PropTypes.bool,
  to: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default LinkAsButton;
