import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import FlexBox from 'src/components/common/flex-box/FlexBox';
import Logo from 'src/components/common/logo/Logo';
import SocialMedia from 'src/components/common/social-media/SocialMedia';
import Link from 'src/components/common/link/Link';
import AppStoreBadge from 'src/components/common/app-store-badge/AppStoreBadge';

const StyledFooter = styled.footer`
  align-items: center;
  display: flex;
  background-color: var(--purple);
  justify-content: center;
  margin-top: auto;
  padding: ${({ isMobile }) => (isMobile ? '2.6rem 3.2rem' : '5.5rem 7.7rem')};
  width: 100%;
`;

const InnerWrapper = styled(FlexBox)`
  max-width: var(--max-width);
`;

const Title = styled.h6`
  color: var(--white);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.6rem;
  margin: 0;
  padding: 0;
`;

const List = styled.ul`
  align-items: ${({ alignItems }) => alignItems || 'center'};
  display: flex;
  gap: ${({ gap }) => gap || '0.25rem'};
  justify-content: flex-start;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  list-style: none;
  margin: 0;
  order: ${({ order }) => order || '0'};
  padding: 0;
  width: ${({ width }) => width || 'auto'};
`;

const Item = styled.li`
  margin: 0;
  padding: 0;
`;

const Copyright = styled.p`
  color: var(--gray-border);
  font-size: 1.4rem;
  margin: 2rem 0 0 0;
  padding: 0;
`;

const DownloadTitle = styled.h6`
  color: var(--gray-border);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.8rem;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const linkStyle = css`
  color: var(--white);
  font-size: 1.6rem;
  text-decoration: none;

  &:hover {
    color: var(--white);
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }
`;

const columnStyle = {
  alignSelf: 'stretch',
  flexDirection: 'column',
  justifyContent: 'flex-start',
};

const cellStyle = {
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '0.25rem',
};

const Footer = () => {
  const { t } = useTranslation();
  const { sm: isMobile } = useBreakpoint();

  const logo = t('footer.logo');
  const links = t('footer.links', { returnObjects: true });
  const socialMedia = t('social_media', { returnObjects: true });
  const copyright = t('footer.copyright');
  const storeLinks = t('app_store', { returnObjects: true });
  const downloadApps = {
    title: t('footer.download_app.title'),
    links: {
      apple: t('footer.download_app.links.apple_store'),
      google: t('footer.download_app.links.google_store'),
    },
  };

  return (
    <StyledFooter isMobile={isMobile}>
      <InnerWrapper
        flexDirection={isMobile ? 'column' : 'row'}
        flexWrap={isMobile ? 'wrap' : 'nowrap'}
        alignItems="flex-start"
        alignSelf="stretch"
        justifyContent="space-between"
        gap={isMobile ? '4.4rem' : '2rem'}
        width="100%"
      >
        <FlexBox
          alignItems="flex-start"
          gap="3rem"
          width={isMobile ? '100%' : '70%'}
          {...columnStyle}
        >
          <FlexBox
            alignItems={isMobile ? 'flex-start' : 'flex-end'}
            gap="5rem"
            flexWrap="wrap"
          >
            {/* Logo */}
            <FlexBox
              alignItems="flex-start"
              flexDirection="column"
              gap="1.2rem"
              order="1"
            >
              <Logo type="icon" width="54" height="54" />
              <Title>{logo}</Title>
            </FlexBox>
            {/* Social Media */}
            <List gap="1.7rem" order={isMobile ? 3 : 2}>
              {Object.keys(socialMedia).map((profile) => (
                <Item key={profile}>
                  <SocialMedia profile={profile} />
                </Item>
              ))}
            </List>
            {/* Links */}
            <List
              gap="2.4rem"
              alignItems={isMobile ? 'start' : 'center'}
              flexDirection={isMobile ? 'column' : 'row'}
              order={isMobile ? 2 : 3}
              width="100%"
            >
              {links.map(({ term, link: termLink }) => (
                <Item key={term}>
                  <Link to={termLink} css={linkStyle}>
                    {term}
                  </Link>
                </Item>
              ))}
            </List>
          </FlexBox>
          {/* Hides copyright message on mobile screens */}
          {!isMobile && (
            <FlexBox {...cellStyle}>
              <Copyright>{copyright}</Copyright>
            </FlexBox>
          )}
        </FlexBox>
        <FlexBox
          alignItems={isMobile ? 'flex-start' : 'flex-end'}
          width={isMobile ? '100%' : '30%'}
          {...columnStyle}
        >
          <FlexBox
            alignItems="flex-start"
            flexDirection={isMobile ? 'row' : 'column'}
            flexWrap={isMobile ? 'wrap' : 'nowrap'}
            gap="2.3rem"
            justifyContent="flex-start"
            width={isMobile ? '100%' : 'auto'}
          >
            <DownloadTitle>{downloadApps.title}</DownloadTitle>
            {Object.keys(storeLinks).map((store) => (
              <AppStoreBadge key={store} type={store} />
            ))}
          </FlexBox>
        </FlexBox>
        {/* Moves copyright message on mobile screen to its own box */}
        {isMobile && (
          <FlexBox {...cellStyle}>
            <Copyright>{copyright}</Copyright>
          </FlexBox>
        )}
      </InnerWrapper>
    </StyledFooter>
  );
};

export default Footer;
